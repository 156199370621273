import LogRocket from 'logrocket'

import { browserHistory } from 'react-router'
import { callWaiter } from 'redux-waiter'
import client from 'apollo/client'
import { camelCase, upperFirst } from 'lodash'
import { selectors as authSelectors, logout as authLogout } from '@hixme/auth-ui'
import { ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER } from '@hixme/role-policy'
import { benefitService, enrollmentService, reimbursementService } from '@hixme/api'
import { getFamilyShape } from '@hixme/person-utils'

import { BENEFIT_TYPE_ICHRA } from '@hixme/benefit-types'

import {
  DELETE_FAMILY_GROUPS,
  GET_OR_CREATE_GROUPS,
  GET_CLIENT_PAYROLL_PERIOD_FOR_USER,
} from 'apollo/queries'
// project
import personsModule from 'store/modules/persons'
import cart from 'store/modules/cart'
import notification from 'modules/notification-manager'
import { createErrorNotification } from 'modules/notification-manager/actions'
import {
  ACCIDENT_PRODUCTS_PATH,
  ADDITIONAL_PRODUCTS_PATH,
  BASIC_LIFE_PRODUCTS_PATH,
  CHIROPRACTIC_PRODUCTS_PATH,
  CRITICAL_ILLNESS_PRODUCTS_PATH,
  DCFSA_PRODUCTS_PATH,
  DENTAL_PRODUCTS_PATH,
  EMPLOYEE_ASSISTANCE_PRODUCTS_PATH,
  FSA_PRODUCTS_PATH,
  FTA_PRODUCTS_PATH,
  GROUP_HEALTH_PATH,
  HEALTH_PRODUCTS_PATH,
  HOSPITAL_INDEMNITY_PRODUCTS_PATH,
  LEGAL_PLAN_PRODUCTS_PATH,
  LEGAL_SHIELD_PRODUCTS_PATH,
  LONG_TERM_DISABILITY_PRODUCTS_PATH,
  LPFSA_PRODUCTS_PATH,
  SHORT_TERM_DISABILITY_PRODUCTS_PATH,
  VISION_PRODUCTS_PATH,
  VOLUNTARY_AD_D_PRODUCTS_PATH,
  VOLUNTARY_LIFE_PRODUCTS_PATH,
  VOLUNTARY_LIFE_SIMPLE_PRODUCTS_PATH,
  VOLUNTARY_LONG_TERM_DISABILITY_PRODUCTS_PATH,
  VOLUNTARY_SHORT_TERM_DISABILITY_PRODUCTS_PATH,
} from 'routes/path-names'

import { getProviderFullName } from 'routes/Provider/utils/index.js'

// module
import t from './actionTypes.js'
import * as selectors from './selectors'
import { ATTESTATIONS_POST, GET_USER_BENEFITS } from './constants'
import {
  getAllProvidersByEmployeeId,
  associatePersonToProvider,
  dissociatePersonFromProvider,
  removeAllPersonsFromProvider,
} from './api/index.js'
import { getEmployeeKey, getPersonsSelector } from '../persons/selectors.js'

import { isPersonSelectedByProviderId } from '../user-session/selectors'
import { evaluateEmpContributionGroup } from 'helpers/evalutateContributionGroup'

const HIXME_USER_SESSION = 'hxus-session'

export const setScrolledToBottom = () => ({
  type: t.SCROLLED_TO_BOTTOM,
})

export const setUserId = (userId) => ({
  type: t.SET_USER_ID,
  payload: userId,
})

export const loadUser = (user) => {
  LogRocket.identify(user.Id, {
    name: user.FullName,
    email: user.EmailAddress,
  })
  return {
    type: t.LOAD_USER,
    payload: user,
  }
}

export const loadPayroll = (payroll) => ({
  type: t.LOAD_PAYROLL,
  payload: payroll,
})

export const loadClientSettings = (payload) => ({
  type: t.LOAD_CLIENT_SETTINGS,
  payload,
})

export const getEnrollmentCutoffTime = (payload) => ({
  type: t.LOAD_ENROLLMENT_CUTOFF_TIME,
  payload,
})

export const loadClientBenefitsEnrollment = (payload) => ({
  type: t.LOAD_CLIENT_BENEFITS_ENROLLMENT,
  payload,
})

export const loadEnrollmentSession = (payload) => ({
  type: t.LOAD_ENROLLMENT_SESSION,
  payload,
})

export const loadFamilyGroups = (payload) => ({
  type: t.SET_FAMILY_GROUPS,
  payload,
})

export const deleteFamilyGroups = () => ({
  type: t.DELETE_FAMILY_GROUPS,
})

export const loadEnrollmentResponses = (payload) => ({
  type: t.LOAD_ENROLLMENT_RESPONSES,
  payload,
})

export const loadPreviousEnrollment = (payload) => ({
  type: t.LOAD_PREVIOUS_ENROLLMENT,
  payload,
})

export const loadICHRAInformation = (payload) => ({
  type: t.LOAD_ICHRA_INFORMATION,
  payload,
})

export const updatingUser = () => ({
  type: t.UPDATING_USER,
})

export const userSessionError = (error) => ({
  type: t.USER_INITIALIZE_ERROR,
  payload: error,
})

export const userInitialized = () => ({
  type: t.USER_INITIALIZED,
})

export const getAttestationsItem = (id) => localStorage.getItem(`attestations-route-${id}`)

export const setAttestationsItem = (id) => {
  localStorage.setItem(`attestations-route-${id}`, true)
}

export const removeAttestationsItem = () => {
  const key = Object.keys(localStorage).find((i) => i.includes('attestations-route'))
  localStorage.removeItem(key)
}

export const setUserLocation = (payload) => ({
  type: t.SET_USER_LOCATION,
  payload,
})

export const setProviderQuestionCheck = (payload) => ({
  type: t.SET_PROVIDER_QUESTION,
  payload,
})

export const loadProviders = (payload) => ({
  type: t.LOAD_PROVIDERS,
  payload,
})

export const addProvider = (payload) => ({
  type: t.ADD_PROVIDER,
  payload,
})

export const removeProvider = (payload) => ({
  type: t.REMOVE_PROVIDER,
  payload,
})

export const deleteProvider = (payload) => ({
  type: t.DELETE_ALL_FROM_PROVIDER,
  payload,
})

export const setFamilyGroups = (groupInformation) => (dispatch) => {
  dispatch(loadFamilyGroups(groupInformation))
  dispatch(cart.actions.getCartByEnrollmentPublicKey())
}

export const patchQuestionResponse = (ProviderSearchCheck) => async (dispatch, getState) => {
  const enrollmentPublicKey = selectors.getEnrollmentPublicKey(getState())
  try {
    await enrollmentService({
      route: 'current/providerSearchCheck/{enrollmentPublicKey}',
      method: 'PATCH',
      params: {
        enrollmentPublicKey,
      },
      body: {
        ProviderSearchCheck,
      },
    })
    dispatch(setProviderQuestionCheck(ProviderSearchCheck))
  } catch (err) {
    dispatch(createErrorNotification('Error registering your answer for Provider'))
  }
}

export const getEnrollmentResponses = () => (dispatch, getState) => {
  const enrollmentPublicKey = selectors.getEnrollmentPublicKey(getState())
  const req = enrollmentService({
    route: '{enrollmentPublicKey}/responses',
    params: { enrollmentPublicKey },
  })

  req.then(loadEnrollmentResponses).catch((e) =>
    dispatch(
      userSessionError({
        title: 'User questions',
        message: 'We were unable to retrieve the questions required for you to enroll.',
        error: e,
      })
    )
  )

  return req
}

/** Use when you need to refresh the enrollment session
 *  Get all the enrollment data
 */
export const getEnrollmentSession = (enrollmentId) => (dispatch, getState) => {
  const enrollmentPublicKey = enrollmentId || selectors.getEnrollmentPublicKey(getState())
  if (!enrollmentPublicKey) {
    dispatch(
      notification.actions.createNotification({
        type: 'error',
        message:
          'We had trouble loading your session. No enrollment found. Please reload the page or contact us.',
      })
    )

    dispatch(
      userSessionError({
        title: 'Enrollment Session',
        message: 'We were unable to retrieve the enrollment session required for you to enroll.',
        error: 'N/A',
      })
    )

    return Promise.reject(new Error('No enrollment public key found'))
  }

  const req = enrollmentService({
    route: '{enrollmentPublicKey}',
    params: { enrollmentPublicKey },
  })

  req
    .then((res) => dispatch(loadEnrollmentSession(res)))
    .catch((e) =>
      dispatch(
        userSessionError({
          title: 'Enrollment Session',
          message: 'We were unable to retrieve the enrollment session required for you to enroll.',
          error: e,
        })
      )
    )

  return req
}

export const initUserId = (userId, persistUser) => (dispatch, getState) => {
  const { userRole, personPublicKey } = authSelectors.getCredentials(getState())
  const storedId = localStorage.getItem(HIXME_USER_SESSION)

  if (userRole && [ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER].indexOf(userRole) > -1) {
    if (persistUser && userId) {
      localStorage.setItem(HIXME_USER_SESSION, userId)
    }

    dispatch(setUserId(userId || storedId || personPublicKey))
    dispatch(loadICHRAInformation({}))
  } else {
    dispatch(setUserId(personPublicKey))
  }
}

export const postAttestations = (vals) => (dispatch, getState) => {
  const enrollmentKey = selectors.getEnrollmentPublicKey(getState())
  return dispatch(
    callWaiter(ATTESTATIONS_POST, {
      requestCreator: () =>
        enrollmentService({
          route: '{enrollmentKey}/responses/OnExchange',
          method: 'POST',
          params: { enrollmentKey },
          body: vals,
        }),
    })
  ).then((res) => dispatch(loadEnrollmentSession(res)))
}

export const getBundleGroups = () => (dispatch, getState) => {
  const enrollmentKey = selectors.getEnrollmentPublicKey(getState())

  return dispatch(
    callWaiter('GET_GROUP_SPLIT', {
      requestCreator: () =>
        enrollmentService({
          route: '{enrollmentKey}/bundlegroups',
          params: { enrollmentKey },
        }),
    })
  ).then((res) => dispatch(getEnrollmentSession(enrollmentKey)).then(() => res))
}

export const putBundleGroups = (body) => (dispatch, getState) => {
  const enrollmentKey = selectors.getEnrollmentPublicKey(getState())

  return dispatch(
    callWaiter('PUT_GROUP_SPLIT', {
      requestCreator: () =>
        enrollmentService({
          route: '{enrollmentKey}/bundlegroups',
          method: 'PUT',
          params: { enrollmentKey },
          body: { ...body },
        }),
    })
  )
    .then(() => {
      dispatch(getEnrollmentSession(enrollmentKey))
    })
    .catch((err) => {
      dispatch(
        createErrorNotification(
          `There was a problem saving your Bundle groups. Please refresh and try again. ${err}`
        )
      )
    })
}

export const resetPutBundleGroups = () => (dispatch, getState) => {
  const enrollmentKey = selectors.getEnrollmentPublicKey(getState())

  return dispatch(
    callWaiter('RESET_PUT_GROUP_SPLIT', {
      requestCreator: () =>
        enrollmentService({
          route: '{enrollmentKey}/bundlegroups/reset',
          method: 'PUT',
          params: { enrollmentKey },
        }),
    })
  )
    .then(() => {
      dispatch(getEnrollmentSession(enrollmentKey))
    })
    .catch((err) => {
      dispatch(
        createErrorNotification(
          `There was a problem saving your Bundle groups. Please refresh and try again. ${err}`
        )
      )
    })
}

export const getICHRAInformation = (params) => (dispatch) =>
  dispatch(
    callWaiter('GET_ICHRA_DATA', {
      requestCreator: () =>
        reimbursementService({
          route:
            'reimbursements/clientId/{clientId}/employeeId/{userId}/benefitEffectiveDate/{benefitEffectiveDate}/expense',
          method: 'GET',
          params,
        }),
    })
  )
    .then((res) => {
      dispatch(
        loadICHRAInformation({
          available: res.expenseShow,
          amount: res.expenseAmount,
        })
      )
    })
    .catch(() => {
      dispatch(createErrorNotification(`There was a problem getting your ICHRA information`))
    })

export const getPayroll = (userId) => async (dispatch) => {
  const { data } = await client.query({
    query: GET_CLIENT_PAYROLL_PERIOD_FOR_USER,
    variables: { employeeId: userId },
  })
  dispatch(loadPayroll(data.getClientPayrollPeriodForUser))
}

export const getProviders = () => async (dispatch, getState) => {
  try {
    const { Person, Dependents } = getPersonsSelector(getState())
    const family = [Person, ...Dependents].reduce(
      (prev, curr) => ({ ...prev, [curr.Id]: curr }),
      {}
    )
    const getPersonsByProvider = (data, id) =>
      data
        .filter((item) => item.providerPublicKey === id)
        .map((person) => {
          const member = family[person.personPublicKey]
          return {
            id: member.Id,
            name: member.FullName,
          }
        })
    const addressFormatter = (address) => ({
      id: address.id,
      streetAddress: `${address.street_line_1}`,
      city: address.city,
      stateProvince: address.state,
      postalCode: address.zip_code,
      phoneNumber: address.phone_numbers.pop(),
    })

    const EmployeePublicKey = getEmployeeKey(getState())
    const { persons, providers } = await dispatch(getAllProvidersByEmployeeId(EmployeePublicKey))
    const currentPeople = persons.filter((person) => family[person.personPublicKey])
    const familyProviders = currentPeople.map((person) => person.providerPublicKey)
    const filteredProviders = providers.filter((provider) => familyProviders.includes(provider.npi))
    const myProviders = filteredProviders.map(({ addresses, name, npi, ...provider }) => {
      const selected = getPersonsByProvider(currentPeople, npi)
      return {
        fullName: getProviderFullName(name),
        addresses: addresses.map((address) => addressFormatter(address)),
        ...provider,
        id: npi,
        selected,
      }
    })
    dispatch(loadProviders(myProviders))
  } catch (err) {
    dispatch(createErrorNotification('There was an error getting your Providers information'))
  }
}

export const addPersonToProvider = (params, person) => async (dispatch, getState) => {
  const EmployeePublicKey = selectors.getUserId(getState())
  try {
    dispatch(addProvider({ ...params, person }))

    const { Provider, ...body } = { ...params }

    await dispatch(associatePersonToProvider({ ...body, EmployeePublicKey }))
  } catch (err) {
    dispatch(removeProvider(params))
  }
}

export const removeProviderFromPerson = (params, person) => async (dispatch) => {
  try {
    dispatch(removeProvider(params))

    const { Provider, ...body } = { ...params }

    await dispatch(dissociatePersonFromProvider(body))
  } catch (err) {
    dispatch(addProvider({ ...params, person }))
  }
}

export const removeAllFromProvider = (ProviderPublicKey) => (dispatch, getState) => {
  try {
    const { Person } = getPersonsSelector(getState())
    dispatch(
      removeAllPersonsFromProvider({
        ProviderPublicKey,
        EmployeePublicKey: Person.Id,
      })
    )
    dispatch(deleteProvider({ ProviderPublicKey }))
  } catch (err) {
    dispatch(createErrorNotification('Something went wrong removing the provider.'))
  }
}

export const togglePersonProvider = (params) => (dispatch, getState) => {
  const { Person, Dependents } = getPersonsSelector(getState())
  const person = [Person, ...Dependents].find((member) => member.Id === params.PersonPublicKey)

  const isAdded = isPersonSelectedByProviderId(
    params.ProviderPublicKey,
    params.PersonPublicKey,
    getState()
  )
  isAdded
    ? dispatch(removeProviderFromPerson(params, person))
    : dispatch(addPersonToProvider(params, person))
}

export const getBenefits = ({ EmployeePublicKey, EffectiveOn = '' }) => (dispatch) =>
  dispatch(
    callWaiter(`${GET_USER_BENEFITS}_${EffectiveOn}`, {
      requestCreator: () =>
        benefitService({
          route: '{EmployeePublicKey}',
          method: 'GET',
          params: {
            EmployeePublicKey,
            EffectiveOn,
          },
        }),
    })
  )

export const initializeUser = () => (dispatch, getState) => {
  const userId = selectors.getUserId(getState())

  if (!userId) {
    dispatch(
      userSessionError({
        title: 'User ID not found',
        message: 'We were unable to retrieve your user ID.',
        error: new Error('User identification not available'),
      })
    )
    return null
  }

  dispatch(updatingUser())
  dispatch(getPayroll(userId))

  // Get Person
  const getPerson = dispatch(personsModule.actions.getFamilyByEmployeeId(userId))
    .then((response) => {
      const family = getFamilyShape(response.Persons)
      dispatch(loadUser(family.employee))
      return response
    })
    .catch((e) =>
      dispatch(
        userSessionError({
          title: 'Family information',
          message: 'We were unable to retrieve your user/family data.',
          error: e,
        })
      )
    )

  const getICHRAEffectiveDate = dispatch(
    getBenefits({
      EmployeePublicKey: userId,
      EffectiveOn: '',
    })
  )
    .then(({ Benefits }) => {
      const currentICHRABenefits =
        Benefits.filter((benefit) => benefit.BenefitType === BENEFIT_TYPE_ICHRA) || null
      if (!currentICHRABenefits) return null
      if (currentICHRABenefits.length > 1) {
        const [currentBenefit] = currentICHRABenefits.filter(
          (current) => !current.BenefitEndDateOld
        )
        return currentBenefit.BenefitEffectiveDate
      }
      return currentICHRABenefits[0].BenefitEffectiveDate
    })
    .catch(() => null)

  const session = enrollmentService({
    route: 'current/{userId}',
    params: { userId },
  })
    .then((enrollment) => {
      const {
        Cart,
        Responses,
        Enrollment,
        Client,
        BenefitCounts,
        ClientBenefitsEnrollment,
        PreviousEnrollment,
      } = enrollment
      // dispatch errors if data isn't found
      if (!Cart || !Responses || !Enrollment || !Client || !BenefitCounts) {
        if (!BenefitCounts) {
          dispatch(
            userSessionError({
              title: 'Benefits',
              message: 'We were unable to retrieve your company benefits.',
              error: new Error('n/a'),
            })
          )
        }

        if (!Client) {
          dispatch(
            userSessionError({
              title: 'Client',
              message: 'We were unable to retrieve your company.',
              error: new Error('n/a'),
            })
          )
        }

        if (!ClientBenefitsEnrollment) {
          dispatch(
            userSessionError({
              title: 'Client Benefits Enrollment',
              message: 'We were unable to retrieve your company benefits enrollment information.',
              error: new Error('n/a'),
            })
          )
        }

        if (!Enrollment) {
          dispatch(
            userSessionError({
              title: 'Enrollment session',
              message: 'We were unable to retrieve your enrollment session.',
              error: new Error('n/a'),
            })
          )
        }

        if (!Cart) {
          dispatch(
            userSessionError({
              title: 'Enrollment Cart',
              message: 'We were unable to retrieve your enrollment cart.',
              error: new Error('n/a'),
            })
          )
        }

        if (!Responses) {
          dispatch(
            userSessionError({
              title: 'Enrollment Responses',
              message: 'We were unable to retrieve your enrollment session responses.',
              error: new Error('n/a'),
            })
          )
        }
      }

      dispatch(cart.actions.loadCart(Cart))
      // Client settings requires BenefitCounts to be included
      dispatch(loadClientSettings({ ...Client, BenefitCounts }))
      dispatch(loadClientBenefitsEnrollment(ClientBenefitsEnrollment))
      dispatch(loadEnrollmentResponses(Responses))
      dispatch(loadEnrollmentSession(Enrollment))
      if (PreviousEnrollment) {
        dispatch(loadPreviousEnrollment(PreviousEnrollment))
      } else {
        // This is to clear any old data
        dispatch(loadPreviousEnrollment(undefined))
      }

      return enrollment
    })
    .catch((e) => {
      dispatch(
        notification.actions.createNotification({
          type: 'error',
          message: 'We had trouble loading your session. Please reload the page',
        })
      )

      dispatch(
        userSessionError({
          title: 'User Session',
          message: 'We were unable to retrieve your session.',
          error: e,
        })
      )
    })

  return Promise.all([getPerson, session, getICHRAEffectiveDate]).then(async (res) => {
    try {
      const [, userSession, ichraDate] = res
      if (ichraDate) {
        await dispatch(
          getICHRAInformation({
            clientId: userSession.Enrollment.ClientPublicKey,
            userId: userSession.Enrollment.EmployeePublicKey,
            benefitEffectiveDate: ichraDate,
          })
        )
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug(err)
    }
    // only initialize the user after family, cart and enrollment is loaded
    dispatch(userInitialized())
  })
}

export const resetUser = () => (dispatch) => {
  localStorage.removeItem(HIXME_USER_SESSION)
  localStorage.removeItem('bundle-groups')
  removeAttestationsItem()
  dispatch(initUserId())
  dispatch(initializeUser())
  dispatch(
    notification.actions.createNotification({
      message: 'Logging out of user...',
    })
  )

  setTimeout(() => {
    window.location = '/'
  }, 1000)
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('bundle-groups')
  removeAttestationsItem()
  dispatch(authLogout())
  setTimeout(() => {
    window.location = '/login'
  }, 10)
}

// note about infinite loop
export const nextRouteMap = [
  HEALTH_PRODUCTS_PATH,
  GROUP_HEALTH_PATH,
  DENTAL_PRODUCTS_PATH,
  VISION_PRODUCTS_PATH,
  FSA_PRODUCTS_PATH,
  LPFSA_PRODUCTS_PATH,
  DCFSA_PRODUCTS_PATH,
  FTA_PRODUCTS_PATH,
  BASIC_LIFE_PRODUCTS_PATH,
  VOLUNTARY_LIFE_PRODUCTS_PATH,
  VOLUNTARY_LIFE_SIMPLE_PRODUCTS_PATH,
  VOLUNTARY_AD_D_PRODUCTS_PATH,
  SHORT_TERM_DISABILITY_PRODUCTS_PATH,
  LONG_TERM_DISABILITY_PRODUCTS_PATH,
  VOLUNTARY_LONG_TERM_DISABILITY_PRODUCTS_PATH,
  VOLUNTARY_SHORT_TERM_DISABILITY_PRODUCTS_PATH,
  EMPLOYEE_ASSISTANCE_PRODUCTS_PATH,
  CHIROPRACTIC_PRODUCTS_PATH,
  LEGAL_PLAN_PRODUCTS_PATH,
  LEGAL_SHIELD_PRODUCTS_PATH,
  HOSPITAL_INDEMNITY_PRODUCTS_PATH,
  CRITICAL_ILLNESS_PRODUCTS_PATH,
  ACCIDENT_PRODUCTS_PATH,
  ADDITIONAL_PRODUCTS_PATH,
]

export function getNextBenefitPath(fromPath, reverse = false, skipShop = false) {
  // There are two health bundle paths - recommended and all
  // this check normalizes the fromPath to be the base HEALTH_PRODUCTS_PATH
  // eslint-disable-next-line
  fromPath = fromPath.indexOf(HEALTH_PRODUCTS_PATH) !== -1 ? HEALTH_PRODUCTS_PATH : fromPath

  // need to clone the array for reverse, since it mutates the array
  const routeMap = reverse ? [...nextRouteMap].reverse() : nextRouteMap
  return (dispatch, getState) => {
    const state = getState()
    let nextPath
    if (!fromPath) {
      nextPath = routeMap[0]
    } else {
      nextPath = routeMap[routeMap.indexOf(fromPath) + 1]
    }

    if (!nextPath) return null

    if (HEALTH_PRODUCTS_PATH === nextPath && !skipShop) {
      if (selectors.healthBundleBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (GROUP_HEALTH_PATH === nextPath) {
      if (selectors.groupHealthBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (DENTAL_PRODUCTS_PATH === nextPath) {
      if (selectors.dentalBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (VISION_PRODUCTS_PATH === nextPath) {
      if (selectors.visionBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (FSA_PRODUCTS_PATH === nextPath) {
      if (selectors.FSABenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (DCFSA_PRODUCTS_PATH === nextPath) {
      if (selectors.DCFSABenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (LPFSA_PRODUCTS_PATH === nextPath) {
      if (selectors.LPFSABenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (FTA_PRODUCTS_PATH === nextPath) {
      if (selectors.FTABenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (BASIC_LIFE_PRODUCTS_PATH === nextPath) {
      if (selectors.basicLifeBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (VOLUNTARY_LIFE_PRODUCTS_PATH === nextPath) {
      if (selectors.voluntaryLifeBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (VOLUNTARY_LIFE_SIMPLE_PRODUCTS_PATH === nextPath) {
      if (selectors.voluntaryLifeSimpleBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (VOLUNTARY_AD_D_PRODUCTS_PATH === nextPath) {
      if (selectors.voluntaryADDBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (SHORT_TERM_DISABILITY_PRODUCTS_PATH === nextPath) {
      if (selectors.shortTermDisabilityBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (LONG_TERM_DISABILITY_PRODUCTS_PATH === nextPath) {
      if (selectors.longTermDisabilityBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (VOLUNTARY_LONG_TERM_DISABILITY_PRODUCTS_PATH === nextPath) {
      if (selectors.voluntaryLongTermDisabilityBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (VOLUNTARY_SHORT_TERM_DISABILITY_PRODUCTS_PATH === nextPath) {
      if (selectors.voluntaryShortTermDisabilityBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (EMPLOYEE_ASSISTANCE_PRODUCTS_PATH === nextPath) {
      if (selectors.employeeAssistanceBenefitIsAvailable(state)) {
        return nextPath
      }
    } else if (CHIROPRACTIC_PRODUCTS_PATH === nextPath) {
      if (selectors.chiropracticBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (LEGAL_PLAN_PRODUCTS_PATH === nextPath) {
      if (selectors.legalPlanBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (LEGAL_SHIELD_PRODUCTS_PATH === nextPath) {
      if (selectors.legalShieldBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (HOSPITAL_INDEMNITY_PRODUCTS_PATH === nextPath) {
      if (selectors.hospitalIndemnityBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (ADDITIONAL_PRODUCTS_PATH === nextPath) {
      if (selectors.interestedBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (CRITICAL_ILLNESS_PRODUCTS_PATH === nextPath) {
      if (selectors.criticalIllnessBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    } else if (ACCIDENT_PRODUCTS_PATH === nextPath) {
      if (selectors.accidentBenefitIsAvailable(state)) {
        return nextPath
      }
      // fall through
    }

    return dispatch(getNextBenefitPath(nextPath, reverse))
  }
}

export const replaceWithNextBenefitRoute = (path) => (dispatch) => {
  const currentPath = typeof path === 'string' ? path : window.location.pathname
  const nextRoutePath = dispatch(getNextBenefitPath(currentPath)) || '/'
  browserHistory.replace(nextRoutePath)
}

export const pushNextBenefitRoute = (path, skipShop) => (dispatch) => {
  const currentPath = typeof path === 'string' ? path : window.location.pathname
  const nextRoutePath = dispatch(getNextBenefitPath(currentPath, false, skipShop)) || '/'
  browserHistory.push(nextRoutePath)
}

export const getNextBenefitRoute = (path, skipShop) => (dispatch) => {
  const currentPath = typeof path === 'string' ? path : window.location.pathname
  return dispatch(getNextBenefitPath(currentPath, false, skipShop)) || '/'
}

export const pushPreviousBenefitRoute = (path) => (dispatch) => {
  const currentPath = typeof path === 'string' ? path : window.location.pathname
  const nextRoutePath = dispatch(getNextBenefitPath(currentPath, true)) || '/'
  browserHistory.push(nextRoutePath)
}

export const postLoginAs = () => async (dispatch, getState) => {
  const state = getState()
  const employeeKey = selectors.getUserId(state)

  try {
    await enrollmentService({
      route: 'login-as-user/{employeeKey}',
      method: 'POST',
      params: { employeeKey },
    })
  } catch (error) {
    console.error('Enrollment Service loginAsUser had an error', error) // eslint-disable-line no-console
  }
}

const normalizeKey = (key) => upperFirst(camelCase(key))
const normalizeKeys = (payload) =>
  Object.entries(payload).reduce((prev, [key, value]) => {
    if (key === '__typename') return prev
    const normalizedKey = normalizeKey(key)
    const isArray = Array.isArray(value)
    if (isArray) {
      // eslint-disable-next-line no-use-before-define
      const result = normalizeArray(value)
      return { ...prev, [normalizedKey]: result }
    }
    return { ...prev, [normalizedKey]: value }
  }, {})

const normalizeArray = (arr) => arr.map((instance) => normalizeKeys(instance))

export const getFamilyGroups = (enrollmentPublicKey) => async (dispatch, getState) => {
  const enrollmentKey = enrollmentPublicKey || selectors.getEnrollmentPublicKey(getState())
  const {
    data: { getOrCreateFamilyGroups },
  } = await client.mutate({
    mutation: GET_OR_CREATE_GROUPS,
    variables: {
      input: {
        enrollmentPublicKey: enrollmentKey,
      },
    },
  })
  await evaluateEmpContributionGroup({
    employeePublicKey: getEmployeeKey(getState()),
    enrollmentPublicKey: enrollmentKey,
  })
  dispatch(loadFamilyGroups(normalizeKeys(getOrCreateFamilyGroups)))
  dispatch(cart.actions.getCartByEnrollmentPublicKey(enrollmentKey))
}

export const deleteFamilyMembers = () => async (dispatch, getState) => {
  const enrollmentKey = selectors.getEnrollmentPublicKey(getState())
  const familyGroups = selectors.getGroups(getState())
  if (!familyGroups) return
  await client.mutate({
    mutation: DELETE_FAMILY_GROUPS,
    variables: {
      input: {
        enrollmentPublicKey: enrollmentKey,
      },
    },
  })
  dispatch(deleteFamilyGroups())
}
