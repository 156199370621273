import { gql } from '@apollo/client'

export const EVALUATE_EMP_CONTRIBUTION_GROUP = gql`
    mutation Mutation($input: EvaluateEmployeeContributionGroupInput!) {
        contribution_evaluateEmployeeContributionGroup(input: $input) {
        contributionGroup
        contributionGroupOld
        employeePublicKey
        enrollmentPublicKey
        updated
        }
    }
`