import { ActivityEntity, ActivityCategory, ActivityActor } from '@surecompanies/activity-log'
import { getUser, isImpersonating } from '../../store/modules/user-session/selectors'
import { selectors as authSelectors } from '@hixme/auth-ui'
import { logActivity } from './index'
import { debounce } from 'lodash'

const source = 'enrollme-web-client'
function capitalizeFirstLetter(string) {
  return string
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const logPageView = debounce(async (state) => {
  try {
    const target = getUser(state)
    if (!target?.Id) {
      console.warn('Activity log disabled for page')
      return
    }

    const { Id: targetId, FullName: targetName, UserRole: targetRole, ClientPublicKey: targetClientPublicKey } = target

    const { referrer } = document;
    const { pathname } = window.location;
    let pageTitle = capitalizeFirstLetter(pathname.split('/').filter((p) => p).join(' - '));
    if( !pageTitle ) {
      pageTitle = 'Home'
    }
    let activity: ActivityEntity = {
      source,
      actorId: targetId,
      actorName: targetName,
      actorType: ActivityActor.User,
      actorRole: targetRole,
      actorClientPublicKey: targetClientPublicKey,
      category: ActivityCategory.UserInteraction,
      action: 'Page View',
      targetId,
      targetName,
      targetRole,
      targetClientPublicKey,
      targetType: ActivityActor.User,
      timestamp: new Date().toISOString(),
      description: `${targetName} visited "${pageTitle}"`,
      additionalData: {
        referrer,
        pageTitle,
        pathname
      }
    }

    if (isImpersonating(state)) {
      const creds = authSelectors.getCredentials(state)
      const { personPublicKey: actorId, firstname, lastname, userRole: actorRole } = creds
      activity = {
        ...activity,
        actorId,
        actorName: `${firstname} ${lastname}`,
        actorRole,
        description: `${firstname} ${lastname} visited "${pageTitle}" as ${targetName}`,
        actorClientPublicKey: undefined,
      }
    }
    logActivity(activity)
  } catch (e) {
    console.error('Error logging Page View', e)
  }
}, 1100)
