import React from 'react'
import { connect } from 'react-redux'

// hixme-ui
import Text from '@hixme-ui/text'
import Button from '@hixme-ui/button'
import { colorTheme } from '@surecompanies/theme'
// module
import { resetUser } from '../actions'

import { getUserName, isImpersonating } from '../selectors'

type LoggedInProps = {
  showLoggedInAs: boolean
  username: string
  reset: () => null
}

const LoggedIn = ({ showLoggedInAs, username, reset }: LoggedInProps) => {
  if (!showLoggedInAs) return null
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 30px',
        background: colorTheme.primary,
        color: '#F1F1F1',
      }}
    >
      <Text thin fontSize="16px">
        You are logged in as {username}
      </Text>
      <Button
        mini
        onClick={reset}
        style={{
          marginLeft: '30px',
          background: '#BD0FE1',
          borderRadius: '20px',
          width: '150px',
        }}
      >
        Sign-out
      </Button>
    </div>
  )
}

const mapStateToProps = (state) => {
  const showLoggedInAs = isImpersonating(state)

  return {
    showLoggedInAs,
    username: getUserName(state),
  }
}

export default connect(mapStateToProps, {
  reset: resetUser,
})(LoggedIn)
