import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux'
import { clearModal } from '@hixme/modal'
import theme, { ThemeProvider } from '@hixme-ui/theme'
import { ThemeProvider as SureCoAntdProvider } from '@surecompanies/theme'
import modal from 'modules/modal'
import { logPageView } from 'modules/analytics'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import appConfig from '../modules/app-config'
import { logPageView as activityLogPageView } from '../modules/activity-log/log-page-view'

import tryScroll from 'helpers/tryScroll'
import useFeatureFlags from '../hooks/useFeatureFlags'
import { DesignSystemProvider } from '@surecompanies/react-design-system'
import { defaultFontFamily } from 'design-system/constants'

function onUpdate(store) {
  return (...args) => {
    store.dispatch(modal.actions.hideModal())
    store.dispatch(clearModal())

    activityLogPageView(store.getState())
    tryScroll()
    logPageView(...args)
  }
}

const AppContainer = ({ routes, store }) => {
  const { flags } = useFeatureFlags()

  // Example usage of feature flags:
  if (flags['qle-v-1']) {
    console.info('QLE v1 is enabled!')
  }

  return (
    <Provider store={store}>
      <DesignSystemProvider>
        <ThemeProvider
          theme={{
            ...theme,
            fontFamily: {
              thinnest: defaultFontFamily,
              thinner: defaultFontFamily,
              thin: defaultFontFamily,
              normal: defaultFontFamily,
              bold: defaultFontFamily,
              bolder: defaultFontFamily,
              boldest: defaultFontFamily,
            },
          }}
        >
          <SureCoAntdProvider>
            <div style={{ height: '100%' }}>
              <Router history={browserHistory} onUpdate={onUpdate(store)}>
                {routes}
              </Router>
            </div>
          </SureCoAntdProvider>
        </ThemeProvider>
      </DesignSystemProvider>
    </Provider>
  )
}

AppContainer.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
}

// Update the export default to use your environment-specific client ID and a sample context:
export default withLDProvider({
  clientSideID: appConfig.getLaunchDarklyConfig().clientSideID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(AppContainer)
