import React from 'react'
import { Link } from 'react-router'
import { Col } from 'react-flexbox-grid'

// hixme-ui
import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'
import { titleFontFamily } from 'design-system/constants'

export type InfoCardProps = {
  title: string
  message: string
  url?: string
  state: 'disabled' | 'start' | 'resume' | 'completed'
  Image: string | (() => React.ReactNode)
  buttonLabel?: string
  bannerText?: string
  badge?: React.ReactNode
  onClick?: () => void
}

const InfoCard = ({
  title,
  Image,
  message,
  url = '',
  state,
  buttonLabel = '',
  bannerText = '',
  onClick,
  badge,
}: InfoCardProps) => {
  const cardStyle =
    state === 'disabled' ? { opacity: 0.4, overflow: 'hidden' } : { overflow: 'hidden' }

  let buttonLabelState = buttonLabel

  if (!buttonLabelState) {
    if (state === 'disabled' || state === 'start') buttonLabelState = 'Start'
    if (state === 'resume') buttonLabelState = 'Resume'
    if (state === 'completed') buttonLabelState = 'View'
  }

  const CardButton = () => {
    if (state === 'disabled') {
      return (
        <Button secondary small block disabled onClick={onClick} role="button">
          {buttonLabelState}
        </Button>
      )
    }
    if (state === 'start') {
      return (
        <Button gold small block onClick={onClick} role="button">
          {buttonLabelState}
        </Button>
      )
    }
    if (state === 'resume') {
      return (
        <Button small block onClick={onClick} role="button">
          {buttonLabelState}
        </Button>
      )
    }
    if (state === 'completed') {
      return (
        <Button secondary small block onClick={onClick} role="button">
          {buttonLabelState}
        </Button>
      )
    }

    return null
  }

  const LinkButton = () => {
    if (onClick) return <CardButton />
    if (url?.substring(0, 4) === 'http') {
      return (
        <a href={url} target="_blank" rel="noreferrer noopener" style={{ width: '100%' }}>
          <CardButton />
        </a>
      )
    }
    return (
      <Link to={url} style={{ width: '100%' }}>
        <CardButton />
      </Link>
    )
  }

  return (
    <Col xs={12} sm={4}>
      <Container
        animate
        heavyShadow
        background="white"
        rounded
        maxWidth="425px"
        height="250px"
        margin="0 auto 20px"
        style={{ position: 'relative', ...cardStyle }}
      >
        <Container
          flex
          noPadding
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <Container flex noPadding direction="column" alignItems="center">
            <Container flex noPadding height="60px">
              {typeof Image === 'function' ? (
                Image()
              ) : (
                <img alt="Card" src={Image} style={{ height: '50px' }} />
              )}
            </Container>
            <Title smallest fontFamily={titleFontFamily}>{title}</Title>
          </Container>
          <Container noPadding textCenter style={{ maxWidth: '100%' }}>
            <Text thin light>
              {message}
            </Text>
          </Container>
          <LinkButton />
        </Container>
        {bannerText && (
          <div
            style={{
              position: 'relative',
              backgroundImage:
                'linear-gradient(-1deg, rgba(255, 140, 0, 0.75), rgba(243, 194, 0, 0.53))',
              color: 'white',
              textAlign: 'center',
              padding: '5px',
              transform: 'rotate(45deg)',
              width: '200px',
              left: '137px',
              top: '-200px',
              fontSize: '12px',
            }}
          >
            {bannerText}
          </div>
        )}
        {badge}
      </Container>
    </Col>
  )
}

export default InfoCard
