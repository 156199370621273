import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Typography } from '@sureco/design-system'
import { Button } from '@surecompanies/core_components'

import DeclineMedicalButtonContainer from 'components/DeclineMedicalButton'
import { containers as userSessionContainers } from 'store/modules/user-session'
import GroupDropdownPicker from '../GroupDropdownPicker/GroupDropdownPicker'

const GroupNav = ({
  currentGroupIndex,
  onNextGroup,
  onBack,
  totalGroupCount,
  declineVisible,
  dropdownGroupVisible,
}) => (
  <Container
    flex
    noPadding
    justifyContent="space-between"
    alignCenter
    width="100%"
    margin="0 0 20px 0"
  >
    <Container flex noPadding alignCenter>
      <Button type="link" onClick={onBack} style={{ padding: 0 }}>
        <ArrowLeftOutlined style={{ color: '#8F9DA8' }} />
        <Typography size={14} color="#8F9DA8">
          Back
        </Typography>
      </Button>
    </Container>
    {dropdownGroupVisible && currentGroupIndex !== null && (
      <Container flex alignCenter width="100%" justifyCenter style={{ gap: '10px' }}>
        <Typography size={16} bold>
          Select a Health Plan for
        </Typography>
        <GroupDropdownPicker />
      </Container>
    )}

    <Container
      flex
      noPadding
      width="100%"
      justifyContent="flex-end"
      alignItems="center"
      style={{ gap: '10px', flex: 1 }}
    >
      {declineVisible && <DeclineMedicalButtonContainer />}
      {currentGroupIndex + 1 !== totalGroupCount && (
        <Button onClick={onNextGroup} size="large" type="primary" style={{ width: '190px' }}>
          <Typography size={14}>Continue</Typography>
        </Button>
      )}
      {currentGroupIndex + 1 === totalGroupCount && (
        <userSessionContainers.SaveAndContinueButtonContainer />
      )}
    </Container>
  </Container>
)

GroupNav.propTypes = {
  currentGroupIndex: PropTypes.number,
  onNextGroup: PropTypes.func,
  totalGroupCount: PropTypes.number,
  declineVisible: PropTypes.bool,
  dropdownGroupVisible: PropTypes.bool,
  // personsName: PropTypes.string,
  onBack: PropTypes.func.isRequired,
}

GroupNav.defaultProps = {
  currentGroupIndex: 0,
  onNextGroup: () => { },
  onPreviousGroup: () => { },
  totalGroupCount: 0,
  declineVisible: false,
  dropdownGroupVisible: false,
  personsInCurrentGroup: [],
  personsName: '...',
}

GroupNav.displayName = 'GroupNav'

export default GroupNav
