import styled from 'styled-components'

import ContentContainer from '@hixme-ui/content-container'
import { defaultFontFamily } from 'design-system/constants'

const Container = styled(ContentContainer)`
  font-family: ${defaultFontFamily};
`

export default Container
