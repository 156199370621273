// Use `useFeatureFlags` if applicable
// Only use LDClient directly if you can't use hooks

import * as LDClient from 'launchdarkly-js-client-sdk'
import appConfig from '../modules/app-config'

let ldClient
let ldClientPromise

export const initializeLDClient = async () => {
    if (ldClient) {
        return ldClient
    }

    if (ldClientPromise) {
        return ldClientPromise
    }

    ldClientPromise = new Promise((resolve, reject) => {
        ldClient = LDClient.initialize(appConfig.getLaunchDarklyConfig().clientSideID, {
            kind: 'user',
            key: 'anonymous',
        })

        ldClient.on('ready', () => {
            console.log('Initialized LaunchDarkly client')
            resolve(ldClient)
        })

        ldClient.on('failed', () => {
            console.error('Failed to initialize LaunchDarkly client')
            ldClientPromise = null // Reset promise if initialization fails
            reject(new Error('Failed to initialize LaunchDarkly client'))
        })
    })

    return ldClientPromise
}

export const getLDClient = () => {
    if (!ldClient) {
        throw new Error('LaunchDarkly client has not been initialized')
    }
    return ldClient
}
