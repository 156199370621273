import { browserHistory } from 'react-router'
import { bundleService, enrollmentService, benefitService } from '@hixme/api'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

import {
  actions as userSessionActions,
  selectors as userSessionSelectors,
} from 'store/modules/user-session'
import { selectors as personSelectors } from 'store/modules/persons'
import waiter, { callWaiter } from 'redux-waiter'
import { createErrorNotification } from 'modules/notification-manager/actions'
import { getEnrollmentPublicKey } from 'store/modules/user-session/selectors'
import { getFamilyGroups } from 'store/modules/user-session/actions'
import { loadCart } from 'store/modules/cart/actions'
import { getAllMedicalProducts } from 'store/modules/cart/selectors'
import { HEALTH_PRODUCTS_PATH } from 'routes/path-names'
import tryScroll from 'helpers/tryScroll'
import { BENEFIT_TYPE_HEALTH } from '@hixme/benefit-types'
import client from 'apollo/client'
import {
  getCurrentGroupBundles,
  getPersonsInCurrentGroup,
  getPersonsInAllGroups,
  getCurrentGroupFavoriteBundles,
  getCurrentGroupIndex,
  getCurrentGroupPreviousBundle,
  getTotalGroupCount,
  getUserEligibility as getRenewalEligibility,
} from './selectors'
import { GET_ENROLLMENT_RENEWAL_ELIGIBILITY } from '../../../../../apollo/queries'
import { LOCAL_STORAGE_NAME, MAX_FAVORITES } from './constants'
import t from './actionTypes.js'
import { datadog, CustomActionNames } from 'helpers/datadog'
import { evaluateEmpContributionGroup } from 'helpers/evalutateContributionGroup'

export const getBundles = (enrollmentPublicKey) => (dispatch) =>
  dispatch(
    callWaiter('GET_BUNDLES', {
      requestCreator: () =>
        bundleService({
          route: 'bundles',
          params: { enrollmentPublicKey },
        }),
    })
  ).then((payload) => {
    dispatch(waiter.actions.clearWaiter('GET_BUNDLES'))
    return payload
  })

export const getUserEligibility = (previousEnrollmentPK, newEnrollmentPK) => async (dispatch) => {
  const { data } = await client.query({
    query: GET_ENROLLMENT_RENEWAL_ELIGIBILITY,
    variables: { input: { previousEnrollmentPK, newEnrollmentPK } },
    fetchPolicy: 'network-only',
  })
  dispatch(loadEligibility(data.getEnrollmentRenewalEligibility))
}

export const loadEligibility = (data) => {
  return {
    type: t.SET_GROUPS_RENEWAL_ELIGIBILITY,
    payload: data,
  }
}

export const loadList = (list) => ({
  type: t.LOAD_LIST,
  payload: list,
})

export const loadFamilyGroups = (groups) => ({
  type: t.LOAD_GROUPS,
  payload: groups,
})

export const unloadList = () => ({
  type: t.UNLOAD_LIST,
})

export const added = (bundleId) => ({
  type: t.BUNDLE_ADDED,
  payload: bundleId,
})

export const setGroupBundleAdded = (groupId, bundleId) => ({
  type: t.SET_AS_ADDED_BY_GROUP,
  payload: {
    groupId,
    bundleId,
  },
})

export const setGroupIndex = (index) => ({
  type: t.SET_GROUP_INDEX,
  payload: index,
})
export const setCurrentGroupId = (id) => ({
  type: t.SET_GROUP_ID,
  payload: id,
})

export const removed = (bundleId) => ({
  type: t.BUNDLE_REMOVED,
  payload: bundleId,
})

const setCartMedicalProductsAsAdded = (list) => (dispatch, getState) => {
  const medicalProds = getAllMedicalProducts(getState())
  if (medicalProds) {
    list.forEach(({ Persons = [], Bundles }, groupIndex) => {
      const groupPersons = Persons.map((p) => p.Id).sort()
      medicalProds.forEach((prod) => {
        const prodPersons = prod.Persons.map((p) => p.Id).sort()
        if (groupPersons.join('') === prodPersons.join('')) {
          Bundles.forEach((b) => {
            if (b.HealthPlanId === prod.HealthPlanId) {
              dispatch(setGroupBundleAdded(groupIndex, b.BundlePublicKey))
            }
          })
        }
      })
    })
  }
}

export const addFavorite = (bundleId) => ({
  type: t.ADD_FAVORITE,
  payload: bundleId,
})

export const toggleFavorite = (bundleId) => ({
  type: t.TOGGLE_FAVORITE,
  payload: bundleId,
})

export const syncCurrentGroupFavesFromLocalStore = (dispatch, state) => {
  const store = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)) || {}
  const personPublicKey = userSessionSelectors.getUserId(state)
  const currentGroupIndex = getCurrentGroupIndex(state)
  const currentGroupBundles = getCurrentGroupBundles(state)

  if (store[personPublicKey] && store[personPublicKey][currentGroupIndex]) {
    Object.values(store[personPublicKey][currentGroupIndex]).forEach((HealthPlanId) => {
      const bundle = Object.values(currentGroupBundles).find((b) => b.HealthPlanId === HealthPlanId)
      if (bundle) {
        dispatch(addFavorite(bundle.BundlePublicKey))
      }
    })
  }
}

export const initList = (list) => (dispatch, getState) => {
  dispatch(loadList(list))
  dispatch(setCartMedicalProductsAsAdded(list))
  syncCurrentGroupFavesFromLocalStore(dispatch, getState())
}

export const setGroupIndexPersonById = (id) => (dispatch, getState) => {
  const persons = getPersonsInAllGroups(getState())

  persons.forEach((group, groupIndex) => {
    const person = group.find((p) => p.Id === id)
    if (person) {
      dispatch(setGroupIndex(groupIndex))
    }
  })
}

export const tryNextGroup = () => ({
  type: t.NEXT_GROUP,
})

export const nextGroup = () => (dispatch, getState) => {
  tryScroll()
  dispatch(tryNextGroup())
  syncCurrentGroupFavesFromLocalStore(dispatch, getState())
}

export const tryPreviousGroup = () => ({
  type: t.PREVIOUS_GROUP,
})

export const previousGroup = () => (dispatch, getState) => {
  tryScroll()
  dispatch(tryPreviousGroup())
  syncCurrentGroupFavesFromLocalStore(dispatch, getState())
}

export const nextBundle = (currentBundleIndex) => ({
  type: t.NEXT_BUNDLE,
  payload: currentBundleIndex,
})

export const previousBundle = (currentBundleIndex) => ({
  type: t.PREVIOUS_BUNDLE,
  payload: currentBundleIndex,
})

export const setMatchBundle = (checked) => ({
  type: t.UPDATE_MATCH_BUNDLE,
  payload: checked,
})

export const setMatchBundleByHios = (checked) => ({
  type: t.UPDATE_MATCH_BUNDLE_BY_HIOS,
  payload: checked,
})

export const updateBundle = (bundles) => ({
  type: t.UPDATE_BUNDLES,
  payload: bundles,
})

export const updateValueScoreFilter = (value) => ({
  type: t.UPDATE_VALUE_SCORE,
  payload: value,
})

export const tryToggleFavorite = (bundleId) => (dispatch, getState) => {
  const state = getState()
  const currentFaveIds = getCurrentGroupFavoriteBundles(state).map((b) => b.HealthPlanId)
  const bundle = getCurrentGroupBundles(state)[bundleId]
  const currentGroupIndex = getCurrentGroupIndex(state)
  if (!bundle.favorite && currentFaveIds.length === MAX_FAVORITES) return

  datadog.addOrRemoveCompareHealth(bundle, currentFaveIds);
  dispatch(toggleFavorite(bundleId))
  const newState = getState()

  const store = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)) || {}
  const newFaveIds = getCurrentGroupFavoriteBundles(newState).map((b) => b.HealthPlanId)
  const personPublicKey = userSessionSelectors.getUserId(newState)

  if (!store[personPublicKey]) {
    store[personPublicKey] = {}
  }

  store[personPublicKey] = {
    ...store[personPublicKey],
    [currentGroupIndex]: newFaveIds,
  }

  localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(store))
}

export const removeFavoritesFromLocalStorage = (state) => {
  const personPublicKey = userSessionSelectors.getUserId(state)
  const store = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)) || {}

  if (store[personPublicKey]) {
    store[personPublicKey] = {}
  }

  localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(store))
}

export const removeUserFavorites = () => ({
  type: t.REMOVE_USER_FAVORITES,
})

export const clearFavorites = () => (dispatch, getState) => {
  removeFavoritesFromLocalStorage(getState())
  dispatch(removeUserFavorites())
  datadog.customAction(CustomActionNames.RemoveAllComparison)
}

const getBody = (state, BundlePublicKey, action) => {
  const Bundles = getCurrentGroupBundles(state)

  if (Bundles[BundlePublicKey]) {
    const Benefit = { ...Bundles[BundlePublicKey] }
    const persons = getPersonsInCurrentGroup(state)
    const PersonPublicKeys = persons.map((p) => p.Id)

    // Pending meta Object
    delete Benefit.active
    delete Benefit.added
    delete Benefit.bundleBenefits
    delete Benefit.bundleCoreBenefits
    delete Benefit.bundlePrice
    delete Benefit.favorite
    delete Benefit.groupPersons
    delete Benefit.order

    Benefit.Action = action
    Benefit.PersonPublicKeys = PersonPublicKeys

    return Benefit
  }
  return {}
}

// qleEnrollmentKey will only be defined when coming from QLE flow
export const addToCart = (BundlePublicKey, qleEnrollmentKey) => (dispatch, getState) => {
  const state = getState()
  const enrollmentKey = qleEnrollmentKey ?? getEnrollmentPublicKey(getState())
  const body = getBody(state, BundlePublicKey, 'add')

  body.RenewalInformation = null
  // skip renewal information for QLE
  if (typeof qleEnrollmentKey === 'undefined') {
    const enrollmentSession = userSessionSelectors.getEnrollmentSession(state)
    const currentGroupIndex = getCurrentGroupIndex(state)
    const currentGroup = enrollmentSession?.FamilyGroups?.FamilyGroups?.[currentGroupIndex]
    const currentGroupId = currentGroup?.Id
    const renewalGroups = getRenewalEligibility(state) ?? []
    const renewalInfo =
      renewalGroups.filter((renewal) => renewal.groupId === currentGroupId)[0] ?? null
    if (renewalInfo) {
      body.RenewalInformation = {
        ...renewalInfo,
        isRenewal: renewalInfo.updatedHealthPlanId === body.HealthPlanId,
      }
    }
  }

  return dispatch(
    callWaiter(`ADD_TO_CART_${BundlePublicKey}`, {
      requestCreator: () =>
        enrollmentService({
          route: '{enrollmentKey}/cart/benefits/HealthBundle',
          method: 'PUT',
          params: { enrollmentKey },
          body,
        }),
    })
  ).then((response) => {
    dispatch(loadCart(response.Cart))

    const localAddedBundles = Object.values(getCurrentGroupBundles(getState())).filter(
      (b) => b.added
    )

    localAddedBundles.forEach((b) => {
      dispatch(removed(b.BundlePublicKey))
      datadog.customAction(CustomActionNames.RemoveHealth, {
        isQLE: !!qleEnrollmentKey,
        enrollmentKey: enrollmentKey,
        bundle: datadog.getBundleAttributesByBundleId(b.BundlePublicKey)
      })
    })

    const cartHealthBundleData = response.Cart.find((b) => b.BenefitType === 'HealthBundle')
    cartHealthBundleData.Benefits.forEach((b) => {
      dispatch(added(b.BundlePublicKey))
      datadog.customAction(CustomActionNames.AddHealth, {
        isQLE: !!qleEnrollmentKey,
        enrollmentKey: enrollmentKey,
        bundle: datadog.getBundleAttributesByBundleId(b.BundlePublicKey)
      })
    })
    dispatch(getFamilyGroups(qleEnrollmentKey))
  })
}

// qleEnrollmentKey will only be defined when coming from QLE flow
export const removeFromCart = (BundlePublicKey, qleEnrollmentKey) => (dispatch, getState) => {
  const enrollmentKey = qleEnrollmentKey ?? getEnrollmentPublicKey(getState())
  const body = getBody(getState(), BundlePublicKey, 'remove')
  return dispatch(
    callWaiter(`REMOVE_FROM_CART_${BundlePublicKey}`, {
      requestCreator: () =>
        enrollmentService({
          route: '{enrollmentKey}/cart/benefits/HealthBundle',
          method: 'PUT',
          params: { enrollmentKey },
          body,
        }),
    })
  ).then((response) => {
    datadog.customAction(CustomActionNames.RemoveHealth, {
      isQLE: !!qleEnrollmentKey,
      enrollmentKey: enrollmentKey,
      bundle: datadog.getBundleAttributesByBundleId(BundlePublicKey)
    })
    dispatch(loadCart(response.Cart))
    dispatch(removed(BundlePublicKey))
    dispatch(getFamilyGroups(qleEnrollmentKey))
  })
}

export const declineBundles = (declineForAll = false) => (dispatch, getState) => {
  const enrollmentKey = getEnrollmentPublicKey(getState())

  let persons = []
  let personKeys = []
  let currentGroupIndex
  let totalGroups

  if (!declineForAll) {
    persons = getPersonsInCurrentGroup(getState())
    currentGroupIndex = getCurrentGroupIndex(getState())
    totalGroups = getTotalGroupCount(getState())
  } else {
    persons = personSelectors.getAllEligiblePersons(getState())
  }
  personKeys = persons.map((p) => p.Id)

  const body = {
    PersonPublicKeys: personKeys,
    Action: 'decline',
  }

  return dispatch(
    callWaiter('DECLINE_BUNDLES', {
      requestCreator: () =>
        enrollmentService({
          route: '{enrollmentKey}/cart/benefits/HealthBundle',
          method: 'PUT',
          params: { enrollmentKey },
          body,
        }),
    })
  )
    .then(async (response) => {
      await evaluateEmpContributionGroup({
        employeePublicKey: personSelectors.getEmployeeKey(getState()),
        enrollmentPublicKey: enrollmentKey,
      })
      datadog.declineBenefits(BENEFIT_TYPE_HEALTH, persons)
      dispatch(loadCart(response.Cart))
      if (currentGroupIndex + 1 < totalGroups) {
        dispatch(nextGroup())
      } else {
        // if no other benefit is available, routes to '/'
        browserHistory.push(
          dispatch(userSessionActions.getNextBenefitPath(HEALTH_PRODUCTS_PATH)) || '/'
        )
      }
    })
    .catch((err) => {
      dispatch(
        createErrorNotification(
          `There was a problem declining bundles. Please refresh and try again. Error: ${err}`
        )
      )
    })
}

// Filters
export const resetFilters = () => (dispatch) => {
  dispatch({ type: t.RESET_FILTERS })
}

export const toggleFilter = (filterName, value) => ({
  type: t.TOGGLE_FILTER,
  payload: {
    filterName,
    value,
  },
})

export const updateMaxCostFilter = (newValue) => ({
  type: t.UPDATE_MAX_COST_FILTER,
  payload: newValue,
})

export const updateMatchBundle = (checked) => (dispatch, getState) => {
  dispatch(setMatchBundle(checked))
  const bundle = getCurrentGroupPreviousBundle(getState())
  if (checked) {
    dispatch(updateValueScoreFilter(bundle.HixmeValue))
    dispatch(toggleFilter('carriers', bundle.CarrierName))
    dispatch(updateMaxCostFilter(bundle.Price))
  }
}

export const updateMatchBundleByHios = (checked) => (dispatch) => {
  dispatch(setMatchBundleByHios(checked))
}

export const setPreviousBundleForGroup = (bundle, groupIndex) => ({
  type: t.SET_PREVIOUS_BUNDLE_FOR_GROUP,
  payload: { bundle, groupIndex },
})

export const matchPersonsToBundles = (bundles) => (dispatch, getState) => {
  const personsArrays = getPersonsInAllGroups(getState())
  personsArrays.forEach((persons, index) => {
    const ppl = persons.map((p) => p.FirstName).sort()
    bundles.forEach((bundle) => {
      const bundlePpl = bundle.Persons.map((p) => p.FirstName).sort()
      const isMatch = isEqual(ppl, bundlePpl)
      if (isMatch) {
        dispatch(setPreviousBundleForGroup(bundle, index))
      }
    })
  })
}

export const getPreviousEnrollment = () => (dispatch, getState) => {
  const EmployeePublicKey = userSessionSelectors.getUserId(getState())
  const EffectiveOn = moment().format('YYYY-MM-DD')
  return dispatch(
    callWaiter(`GET_BENEFITS_${EmployeePublicKey}`, {
      requestCreator: () =>
        benefitService({
          route: '{EmployeePublicKey}',
          method: 'GET',
          params: { EmployeePublicKey, EffectiveOn },
        }),
    })
  ).then((response) => {
    const allPreviousHealthBundles = response.Benefits.filter(
      (ben) => ben.BenefitType === BENEFIT_TYPE_HEALTH
    )
    if (allPreviousHealthBundles.length > 0) {
      const sorted = sortBy(allPreviousHealthBundles, 'BenefitEffectiveDate')
      dispatch(matchPersonsToBundles(sorted))
    }
  })
}

export const updateSelectedProviders = (providers) => ({
  type: t.UPDATE_SELECTED_PROVIDERS,
  payload: providers,
})
