import axios, { AxiosResponse } from 'axios'

import { isProduction } from '../app-config/environment'
import { ActivityEntity } from '@surecompanies/activity-log'

const PDF_REST_API_URL = {
  dev: 'https://pdfservice.dev.surecolabs.com',
  prod: 'https://pdfservice.docday.com'
}

export const getApiUrl = () => {
  if (isProduction()) {
    return PDF_REST_API_URL.prod
  }
  return PDF_REST_API_URL.dev
}

export const logActivity = (payload: ActivityEntity): Promise<AxiosResponse<any> | void> => {
  const baseURL = getApiUrl()
  return axios.post(
    'activities',
    {
      timestamp: new Date().toISOString(),
      ...payload
    },
    {
      baseURL,
    }
  ).catch((e) => console.error(e))
}
