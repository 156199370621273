import client from 'apollo/client'
import { EVALUATE_EMP_CONTRIBUTION_GROUP } from 'apollo/contribution'
import { initializeLDClient, getLDClient } from './launchDarklyClient'

export const evaluateEmpContributionGroup = async ({
    employeePublicKey,
    enrollmentPublicKey
}: {
    employeePublicKey: string,
    enrollmentPublicKey: string,
}) => {
    let shouldEvaluateContributionGroup = false
    try {
        await initializeLDClient()
        const ldClient = getLDClient()
        shouldEvaluateContributionGroup = ldClient.variation('temp.ui.dynamic.contribution.group', false)
    }
    catch (e) {
        console.error('Initializing LD Client (No React) Failed')
        console.error(e)
    }

    if (!shouldEvaluateContributionGroup) {
        return Promise.resolve(null)
    }

    try {
        return client.mutate({
            mutation: EVALUATE_EMP_CONTRIBUTION_GROUP,
            variables: {
                input: {
                    employeePublicKey,
                    enrollmentPublicKey
                }
            },
        });
    } catch (error) {
        console.error('Error evaluating contribution group:', error);
    }
}