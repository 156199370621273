import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

import TermsAndConditionsModalContainer from 'containers/TermsAndConditionsModalContainer'

import classes from './SideNav.scss'

const SideNav = ({ logout, userName, person, isAdmin }) => (
  <Container flex noPadding role="navigation" direction="column">
    <Container flex noPadding direction="column">
      <Text white>Welcome {userName}</Text>
      {isAdmin && (
        <Text light small>
          {person.EmailAddress}
        </Text>
      )}
    </Container>

    <div className={classes.spacedLarge}>
      <div>
        <Link to="/">
          <Container flex noPadding>
            <Icon home light size={1.2} />
            <Text white style={{ marginLeft: '20px' }}>
              Home
            </Text>
          </Container>
        </Link>
      </div>

      <div>
        <Link to="/settings">
          <Container flex noPadding>
            <Icon cog light size={1.2} />
            <Text white style={{ marginLeft: '20px' }}>
              Settings
            </Text>
          </Container>
        </Link>
      </div>
    </div>

    <div className={classes.spacedMedium}>
      <Container flex noPadding direction="column">
        <TermsAndConditionsModalContainer />

        <Text onClick={logout} cursor="pointer" light small>
          Sign out
        </Text>
      </Container>
    </div>
  </Container>
)

SideNav.displayName = 'SideNav'

SideNav.propTypes = {
  logout: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  person: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
}

SideNav.defaultProps = {
  isAdmin: false,
}

export default SideNav
