import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import BenefitPrice from '@hixme-ui/benefit-price'
import Currency from '@hixme-ui/currency'

import { monthlyCostByPayrollCycle } from 'modules/benefit-utils'

import { getUserPayroll } from 'store/modules/user-session/selectors'
import { getEmployee } from '../../selectors'
import { defaultFontFamily } from 'design-system/constants'

const CustomPrice = styled(BenefitPrice)`
  font-family: ${defaultFontFamily};
  font-weight: 700;
`

const PersonBenefitPrice = ({
  payrollCycle,
  // payroll,
  price,
  compact,
  ignorePayrollCycle,
  priceStyle,
  label,
  primary,
  blue,
  ...rest
}) => {
  const computedPrice = monthlyCostByPayrollCycle(price, payrollCycle)
  return (
    <span>
      {compact ? (
        <Currency
          {...rest}
          blue={price < 0 || blue}
          primary={primary}
          value={ignorePayrollCycle ? price : computedPrice}
        />
      ) : (
        <span>
          {ignorePayrollCycle ? (
            <CustomPrice value={price} label={label} style={priceStyle} blue={primary} />
          ) : (
            <CustomPrice
              value={price > 0 ? price : 0}
              payCycle={payrollCycle}
              label={label}
              blue={primary}
            />
          )}
        </span>
      )}
    </span>
  )
}

PersonBenefitPrice.propTypes = {
  payrollCycle: PropTypes.number,
  payroll: PropTypes.object,
  price: PropTypes.number,
  compact: PropTypes.bool,
  primary: PropTypes.bool,
  blue: PropTypes.bool,
  ignorePayrollCycle: PropTypes.bool,
  priceStyle: PropTypes.object,
  label: PropTypes.string,
}

PersonBenefitPrice.defaultProps = {
  payrollCycle: 12,
  payroll: { payrollCycle: 12, monthsOfCoverage: 12 },
  price: 0,
  compact: false,
  ignorePayrollCycle: false,
  blue: false,
  primary: false,
  priceStyle: {},
  label: '',
}

const mapStateToProps = (state) => ({
  payroll: getUserPayroll(state),
  payrollCycle: getEmployee(state).PayrollCycle,
})

export default compose(connect(mapStateToProps)(PersonBenefitPrice))
