import React, { useState } from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'

import LoadingFilterOptions from './LoadingFilterOptions'
import FilterPanelHeader from './components/FilterPanelHeader/FilterPanelHeader'
import FilterPanelBodyContainer from '../../containers/FilterPanelBodyContainer'

const stylesOpen = {
  background: 'white',
  height: 'auto',
  width: '100%',
}

const stylesClosed = {
  visibility: 'hidden',
  height: '0',
  width: '100%',
}

const FilterOptions = ({
  currentGroupBundleCount,
  currentGroupBundleFilteredCount,
  isProcessing,
  open,
  resetFilters,
  toggleOpen,
}) => {
  const [isResetFilter, setIsResetFilter] = useState(false)

  const resetFilterClicked = () => {
    setIsResetFilter(true)
    resetFilters()
  }

  return (
    <Container flex noPadding direction="column" shadow>
      {isProcessing && <LoadingFilterOptions />}
      {!isProcessing && (
        <Container noPadding>
          <FilterPanelHeader
            opened={open}
            onResetFilters={resetFilterClicked}
            currentCount={currentGroupBundleFilteredCount}
            totalCount={currentGroupBundleCount}
            onToggle={() => toggleOpen((opened) => !opened)}
          />
          {open && (
            <div style={open ? stylesOpen : stylesClosed}>
              <FilterPanelBodyContainer resetFilter={isResetFilter} />
            </div>
          )}
        </Container>
      )}
    </Container>
  )
}

FilterOptions.propTypes = {
  resetFilters: PropTypes.func.isRequired,
  currentGroupBundleCount: PropTypes.number,
  currentGroupBundleFilteredCount: PropTypes.number,
  isProcessing: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
}

FilterOptions.defaultProps = {
  currentGroupBundleCount: 0,
  currentGroupBundleFilteredCount: 0,
  currentGroupIndex: 0,
  isProcessing: false,
}

FilterOptions.displayName = 'FilterOptions'

export default FilterOptions
